import { FC } from 'react';

type ShieldCheckProps = {
	height?: number;
	width?: number;
};

const ShieldCheck: FC<ShieldCheckProps> = ({ height = 27, width = 26 }) => {
	return (
		<svg
			aria-labelledby="shield-check"
			width={width}
			height={height}
			viewBox="0 0 26 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="shield-check" lang="ja">
				{/* Shield Check illustration */}
				シールドチェックのイラスト
			</title>
			<path
				d="M23.8089 6.06393C23.7053 4.66541 22.6124 3.57064 21.2637 3.51158L20.2054 3.46518C18.9278 3.41033 17.6846 3.00744 16.602 2.3008L14.3392 0.822129C13.5206 0.288457 12.4806 0.288457 11.662 0.822129L9.39916 2.3008C8.31651 3.00744 7.07338 3.41033 5.79573 3.46518L4.73745 3.51158C3.3887 3.57064 2.29588 4.66541 2.19229 6.06393C1.80229 11.3289 2.03791 23.4705 12.0337 26.4363C12.6654 26.624 13.3378 26.624 13.9695 26.4363C23.9632 23.4705 24.2009 11.3289 23.8109 6.06393H23.8089Z"
				fill="#18F0B8"
			/>
			<path
				opacity="0.1"
				d="M23.8083 6.06445C23.7047 4.66594 22.6119 3.57117 21.2631 3.51211L20.2048 3.4657C18.9272 3.41086 17.6841 3.00797 16.6014 2.30133L14.3386 0.822656C13.9303 0.554766 13.4652 0.421875 13 0.421875V26.5781C13.325 26.5781 13.652 26.5317 13.9689 26.4368C23.9627 23.471 24.2003 11.3295 23.8103 6.06445H23.8083Z"
				fill="#6675F6"
			/>
			<path
				d="M13 19.1426C16.0009 19.1426 18.4336 16.6163 18.4336 13.5C18.4336 10.3837 16.0009 7.85742 13 7.85742C9.99911 7.85742 7.56641 10.3837 7.56641 13.5C7.56641 16.6163 9.99911 19.1426 13 19.1426Z"
				fill="white"
			/>
			<path
				d="M18.4336 13.5C18.4336 16.6155 16.0002 19.1426 13 19.1426V7.85742C16.0002 7.85742 18.4336 10.3845 18.4336 13.5Z"
				fill="#D1D3D2"
			/>
			<path
				d="M12.2063 16.3895L9.66117 13.8097C9.3707 13.5165 9.36461 13.0335 9.64898 12.7339C9.93132 12.4323 10.3965 12.426 10.6849 12.7213L12.1718 14.2274L15.2999 10.8439C15.5802 10.5402 16.0454 10.5296 16.3359 10.8228C16.6284 11.1139 16.6365 11.597 16.3562 11.8986L12.2043 16.3895H12.2063Z"
				fill="#18F0B8"
			/>
			<path
				opacity="0.5"
				d="M13.0001 13.3309L15.2995 10.8439C15.5798 10.5402 16.0449 10.5296 16.3354 10.8228C16.6279 11.1139 16.636 11.597 16.3557 11.8986L12.998 15.531V13.333L13.0001 13.3309Z"
				fill="#17A57C"
			/>
		</svg>
	);
};

export { ShieldCheck };
