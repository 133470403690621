import { Trans, useTranslation } from 'next-i18next';
import { FC, ReactNode } from 'react';

import { SteppedConsumptionRate } from '@/services/typed-graphql-sdk';
import { sortDayNightTimeOfUseCharges } from '@/utils/product';

export const ConsumptionCharges: FC<{
	consumptionCharges: SteppedConsumptionRate[];
}> = ({ consumptionCharges }) => (
	<div className="flex max-w-sm flex-wrap justify-between">
		{consumptionCharges.map((charge, index) => (
			<div
				className="flex flex-col"
				key={(charge.pricePerUnitIncTax + index).toString()}
			>
				<div className="whitespace-nowrap text-base font-bold">
					{charge.pricePerUnitIncTax} <span className="text-xxs">円/kWh</span>
				</div>
				{charge.stepStart || charge.stepEnd ? (
					<div className="whitespace-nowrap text-xs text-pollution">
						{consumptionChargeStepLabel(charge)}
					</div>
				) : null}
			</div>
		))}
	</div>
);

export const ConsumptionChargesProductCard: FC<{
	consumptionCharges: SteppedConsumptionRate[];
}> = ({ consumptionCharges }) => (
	<div className="flex max-w-sm flex-col flex-wrap justify-between space-y-2 text-sm">
		{consumptionCharges.map((charge, index) => (
			<div
				className="flex items-center space-x-2"
				key={(charge.pricePerUnitIncTax + index).toString()}
			>
				<div className="whitespace-nowrap font-bold">
					<Trans
						i18nKey="obj:product.price-perkwh"
						values={{ price: charge.pricePerUnitIncTax }}
						components={{ span: <span>円/kWh</span> }}
					/>
				</div>
				{charge.stepStart || charge.stepEnd ? (
					<div className="whitespace-nowrap text-xs text-pollution">
						{consumptionChargeStepLabel(charge)}
					</div>
				) : null}
			</div>
		))}
	</div>
);

// Display consumption charges in Simple Octopus product cards
export const ConsumptionChargesProductCardSimple: FC<{
	consumptionCharges: SteppedConsumptionRate[];
}> = ({ consumptionCharges }) => (
	<div className="space-y-2 text-xs">
		{consumptionCharges.map((charge, index) => {
			return (
				<div key={`${charge}${index}`}>
					{charge.stepStart || charge.stepEnd
						? `${consumptionChargeStepLabel(charge)}・`
						: null}
					<Trans
						i18nKey="obj:product.price-perkwh"
						values={{ price: charge.pricePerUnitIncTax }}
						components={{ span: <span className="mr-2">円/kWh</span> }}
					/>
				</div>
			);
		})}
	</div>
);

const TimeOfUseChargeDescription: FC<{
	label: ReactNode;
	price: number;
	time?: ReactNode;
	variant?: 'default' | 'card';
}> = ({ label, price, time: subLabel, variant }) => (
	<div className="flex justify-between xs:text-base">
		<div className="flex flex-col text-ice">
			{subLabel ? (
				<>
					<span>{label}</span>
					<span className="text-xs">{subLabel}</span>
				</>
			) : (
				label
			)}
		</div>
		<b className="ml-5">
			<Trans
				i18nKey="obj:product.price-perkwh"
				values={{ price }}
				components={{ span: <span /> }}
			/>
		</b>
		{variant === 'default' && <div></div>}
	</div>
);

/**
 * Display Solar/Night/Home time of use consumption charges for Solar Octopus
 */
export const ConsumptionChargesSolarOctopusThreeStepTimeOfUse: FC<{
	consumptionCharges: SteppedConsumptionRate[];
	variant?: 'default' | 'card';
}> = ({ consumptionCharges, variant = 'default' }) => {
	const { t } = useTranslation();

	const [
		solarTimeConsumptionCharge,
		homeTimeConsumptionCharge,
		nightTimeConsumptionCharge,
	] = consumptionCharges.sort(
		(a, b) => Number(b.pricePerUnitIncTax) - Number(a.pricePerUnitIncTax)
	);

	return (
		<div className="flex flex-col gap-3">
			<TimeOfUseChargeDescription
				price={Number(solarTimeConsumptionCharge.pricePerUnitIncTax)}
				label={t('obj:final.solar.solar-time')}
				time="08:00 ~ 16:00"
				variant={variant}
			/>
			<TimeOfUseChargeDescription
				price={Number(nightTimeConsumptionCharge.pricePerUnitIncTax)}
				label={t('obj:final.solar.night-time')}
				time="22:00 ~ 6:00"
				variant={variant}
			/>
			<TimeOfUseChargeDescription
				price={Number(homeTimeConsumptionCharge.pricePerUnitIncTax)}
				label={t('obj:final.solar.home-time')}
				time={
					<>
						06:00 ~ 8:00 <br />
						16:00 ~ 22:00
					</>
				}
				variant={variant}
			/>
		</div>
	);
};

/**
 * Display Night/Day time of use consumption charges for Solar Octopus
 */
export const ConsumptionChargesSolarOctopusTwoStepTimeOfUse: FC<{
	consumptionCharges: SteppedConsumptionRate[];
	variant?: 'default' | 'card';
}> = ({ consumptionCharges, variant = 'default' }) => {
	const { t } = useTranslation();

	const [nightTimeConsumptionCharge, dayConsumptionCharge] =
		sortDayNightTimeOfUseCharges(consumptionCharges);

	return (
		<div className="flex flex-col gap-3">
			<TimeOfUseChargeDescription
				price={Number(dayConsumptionCharge.pricePerUnitIncTax)}
				label={t('obj:final.solar.daytime')}
				time="08:00 ~ 16:00"
				variant={variant}
			/>
			<TimeOfUseChargeDescription
				price={Number(nightTimeConsumptionCharge.pricePerUnitIncTax)}
				label={t('obj:final.solar.other-than-daytime')}
				variant={variant}
			/>
		</div>
	);
};

/**
 * Display time of use consumption charges for Solar Octopus
 */
export const ConsumptionChargesSolarOctopusTimeOfUse: FC<{
	consumptionCharges: SteppedConsumptionRate[];
	variant?: 'default' | 'card';
}> = ({ consumptionCharges, variant = 'default' }) => {
	return consumptionCharges.length === 2 ? (
		<ConsumptionChargesSolarOctopusTwoStepTimeOfUse
			consumptionCharges={consumptionCharges}
			variant={variant}
		/>
	) : consumptionCharges.length === 3 ? (
		<ConsumptionChargesSolarOctopusThreeStepTimeOfUse
			consumptionCharges={consumptionCharges}
			variant={variant}
		/>
	) : null;
};

/**
 * Display EV Night/Day time of use consumption charges
 */
export const ConsumptionChargesEVTimeOfUse: FC<{
	consumptionCharges: SteppedConsumptionRate[];
	variant?: 'default' | 'card';
}> = ({ consumptionCharges, variant = 'default' }) => {
	const { t } = useTranslation();

	const [evTimeConsumptionCharge, standardConsumptionCharge] =
		sortDayNightTimeOfUseCharges(consumptionCharges);

	return (
		<div className="flex flex-col gap-3" data-testid="ev-consumption-charges">
			<TimeOfUseChargeDescription
				price={Number(evTimeConsumptionCharge.pricePerUnitIncTax)}
				label={t('obj:final.ev.ev-time')}
				time="02:00 ~ 04:00"
				variant={variant}
			/>
			<TimeOfUseChargeDescription
				price={Number(standardConsumptionCharge.pricePerUnitIncTax)}
				label={
					<>
						{t('obj:final.ev.standard')}
						<br />
						{t('obj:final.ev.time')}
					</>
				}
				variant={variant}
			/>
		</div>
	);
};

export const consumptionChargeStepLabel = (
	charge: Pick<SteppedConsumptionRate, 'stepEnd' | 'stepStart'>
): string =>
	`(${
		charge.stepStart && charge.stepStart > 0
			? charge.stepStart + 1
			: (charge.stepStart ?? 0)
	}${charge.stepEnd ? ` - ${charge.stepEnd}kWh` : 'kWh +'})`;
