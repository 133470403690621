import { FC } from 'react';

const TrophyGold: FC<{ height?: number; width?: number }> = ({
	width = 27,
	height = 33,
}) => (
	<svg
		aria-labelledby="trophy-gold"
		width={width}
		height={height}
		viewBox="0 0 27 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<title id="trophy-gold" lang="ja">
			{/* Golden Trophy illustration */}
			ゴールドトロフィーのイラスト
		</title>
		<path
			d="M13.5783 17.6462C13.5839 17.678 13.5577 17.7154 13.5633 17.7472C13.4881 18.2525 13.1154 18.7445 12.0954 18.7271C11.0754 18.7096 10.647 18.3257 10.447 17.9344C10.4095 17.9082 10.4302 17.839 10.3872 17.7809C10.2938 17.4365 10.3707 17.1277 10.3857 17.0267C10.3801 16.9949 10.4064 16.9575 10.4064 16.9575L13.4198 16.5587C13.4198 16.5587 13.4684 16.6485 13.4964 16.8076C13.5468 17.094 13.5972 17.3804 13.5783 17.6462Z"
			fill="#FFA26B"
		/>
		<path
			d="M16.2493 24.0656L7.75601 24.0832C11.1086 23.0015 11.0364 18.4872 11.0364 18.4872C11.5465 16.7244 12.9117 18.5182 12.9117 18.5182C12.9173 18.55 12.9229 18.5818 12.9229 18.5818C13.1024 22.0275 15.267 23.5167 15.9986 23.9457C16.1482 24.0506 16.2493 24.0656 16.2493 24.0656Z"
			fill="#FFA26B"
		/>
		<path
			d="M13.0776 16.8481C12.5516 16.8421 12.3327 16.7166 12.1719 16.5481C12.0054 16.3477 12.0617 16.1082 12.1348 15.9641L12.8663 16.3931C12.945 16.2809 12.9282 16.1854 12.8852 16.1274C12.8684 16.0319 12.7936 15.9795 12.7618 15.9851C12.7936 15.9795 13.1079 16.0882 14.5398 15.8364C16.8515 15.3642 18.3026 14.2889 19.7844 11.896C21.0096 9.90904 20.8961 5.53321 20.8257 4.20059L19.4501 4.2129C19.219 5.69694 17.5302 6.9125 17.4777 6.98733L16.9672 6.32262C17.4633 5.97292 18.6749 4.84131 18.6287 3.8325L18.5822 3.38142L21.5992 3.37567L21.6345 3.76311C21.6474 4.02327 22.0708 9.788 20.4897 12.3624C18.8824 14.9742 17.2572 16.1786 14.691 16.6955C13.9853 16.7868 13.4388 16.8501 13.0776 16.8481Z"
			fill="#F4905D"
		/>
		<path
			d="M19.0285 24.7571L4.98967 24.7658L4.99654 29.0948L19.0353 29.086L19.0285 24.7571Z"
			fill="#1ED6A6"
		/>
		<path
			d="M16.275 26.4524L16.2746 27.0102C16.2687 27.5361 15.853 27.97 15.3271 27.9641L8.8308 27.9585C8.30489 27.9526 7.87092 27.5368 7.87684 27.0109L7.87716 26.4532C7.88308 25.9273 8.29882 25.4933 8.82473 25.4992L15.321 25.5049C15.8469 25.5108 16.2491 25.9321 16.275 26.4524Z"
			fill="#FFA26B"
		/>
		<path
			d="M12.511 17.1765C12.32 17.2101 12.1291 17.2436 11.9382 17.2772C9.86813 17.4445 8.21093 15.6693 7.37161 13.8814C6.09482 11.2848 6.48003 8.62548 6.47604 5.80499C6.47987 5.6403 6.47764 3.01633 6.47764 3.01633L15.9909 3.0162C17.0746 3.02243 17.8288 3.021 17.8288 3.021L17.8326 9.38439C17.769 9.39559 17.827 15.8807 12.511 17.1765Z"
			fill="#FFA26B"
		/>
		<path
			d="M13.5782 17.6447C13.5838 17.6766 13.5838 17.6766 13.5838 17.6766C13.1027 17.9252 12.0355 18.5722 10.4189 17.7739C10.3256 17.4295 10.4025 17.1207 10.4175 17.0197C10.8329 17.1435 12.2757 17.513 13.5076 16.8699C13.5468 17.0926 13.5971 17.379 13.5782 17.6447Z"
			fill="#F4905D"
		/>
		<path
			d="M16.0042 23.9759L13.5187 24.0194C12.0014 23.0397 11.9309 18.7219 11.9309 18.7219C12.4306 18.7652 12.9285 18.612 12.9285 18.612C13.1455 22.0839 15.2726 23.5469 16.0042 23.9759Z"
			fill="#F4905D"
		/>
		<path
			d="M12.5234 17.2399C12.3325 17.2735 12.1041 17.2809 11.9076 17.2826C11.8758 17.2882 11.8758 17.2882 11.8702 17.2564C11.8702 17.2564 14.9892 15.5925 15.6859 11.5334C16.3169 7.84676 15.802 4.9193 15.9603 3.0216C17.044 3.02784 17.7982 3.0264 17.7982 3.0264L17.802 9.3898C17.7702 9.3954 17.8657 15.9067 12.5234 17.2399Z"
			fill="#F4905D"
		/>
		<path
			d="M11.7416 17.2786C6.31633 16.4614 7.50394 4.93496 7.69966 3.06349L9.70225 3.07213C8.72906 16.0042 11.7416 17.2786 11.7416 17.2786Z"
			fill="#FFBD97"
		/>
		<path
			d="M11.6549 24.0508C11.6549 24.0508 11.623 24.0564 11.5332 24.105L9.89179 24.0985C9.92361 24.0929 9.94983 24.0555 9.94983 24.0555C11.3953 22.9483 11.3567 18.6249 11.3567 18.6249L11.4895 18.6344C11.4895 18.6344 11.4344 20.1859 11.5177 21.7786C11.5691 23.377 11.6549 24.0508 11.6549 24.0508Z"
			fill="#FFBD97"
		/>
		<path
			d="M16.2747 26.4535L16.2744 27.0112C16.2685 27.5372 15.8528 27.9711 15.3268 27.9652L14.0467 27.9607L14.0406 25.5015L15.3208 25.5059C15.8467 25.5119 16.2488 25.9332 16.2747 26.4535Z"
			fill="#F4905D"
		/>
		<path
			d="M11.6135 25.5026L9.74374 25.5034L9.74981 27.9627L11.6195 27.9619L11.6135 25.5026Z"
			fill="#FFBD97"
		/>
		<path
			d="M14.4981 26.6036L9.54219 26.5895C9.44113 26.5744 9.32888 26.4958 9.34392 26.3947C9.35895 26.2937 9.43762 26.1814 9.53867 26.1964L14.4946 26.2105C14.5957 26.2256 14.7079 26.3042 14.6929 26.4053C14.6779 26.5063 14.5936 26.5868 14.4981 26.6036Z"
			fill="#1ED6A6"
		/>
		<path
			d="M14.4942 27.5215L9.53828 27.5074C9.43723 27.4924 9.32498 27.4137 9.34001 27.3127C9.35504 27.2116 9.43371 27.0994 9.53477 27.1144L14.4907 27.1285C14.5918 27.1435 14.704 27.2222 14.689 27.3232C14.7114 27.4505 14.6271 27.531 14.4942 27.5215Z"
			fill="#1ED6A6"
		/>
		<path
			d="M19.2738 29.7004L5.03853 29.7109C4.74095 29.6976 4.50526 29.4766 4.48671 29.1847C4.49998 28.8871 4.72097 28.6514 5.01294 28.6328L19.2482 28.6223C19.5458 28.6356 19.7815 28.8566 19.8001 29.1486C19.7868 29.4461 19.5658 29.6818 19.2738 29.7004Z"
			fill="#12C093"
		/>
		<path
			d="M19.2426 25.0461L5.00728 25.0566C4.7097 25.0433 4.47401 24.8223 4.45546 24.5304C4.46873 24.2328 4.68972 23.9971 4.9817 23.9785L19.217 23.968C19.5146 23.9813 19.7503 24.2023 19.7688 24.4943C19.793 24.8181 19.5402 25.0594 19.2426 25.0461Z"
			fill="#12C093"
		/>
		<path
			d="M10.9088 16.8337C11.4347 16.8396 11.6725 16.6994 11.8092 16.5441C11.9722 16.3514 11.9274 16.0969 11.8358 15.949L11.0906 16.3753C11.0046 16.2592 11.0515 16.1525 11.0459 16.1207C11.0927 16.014 11.1508 15.971 11.1826 15.9654C11.1508 15.971 10.8437 16.0906 9.38034 15.7903C7.06546 15.3117 5.63244 14.2516 4.12906 11.8589C2.90055 9.90982 3.00978 5.49481 3.0723 4.17164L4.44792 4.15933C4.67914 5.66047 6.33666 6.87796 6.44331 6.92481L6.94716 6.24572C6.46074 5.90481 5.24487 4.77367 5.29412 3.74807L5.31122 3.28579L2.29422 3.29154L2.26592 3.69018C2.24146 3.92411 1.81515 9.70707 3.41575 12.2796C5.02194 14.8839 6.64204 16.0751 9.22827 16.6044C10.0161 16.7939 10.5476 16.8316 10.9088 16.8337Z"
			fill="#FFA26B"
		/>
	</svg>
);

export { TrophyGold };
