import clsx from 'clsx';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';

import { StandingChargeFragment } from '@/services/typed-graphql-sdk';
import { currencyFormatter } from '@/utils/formatters/currencyFormatter';

export type StandingChargesAllElectricVariant =
	| 'onboarding'
	| 'mypage'
	| 'tariffs';

/**
 * Display Standing charges for All Electric tariff
 * - Standing charges structure differs by grid operator area
 * - Grid areas 01, 03, 09 have only one standing charge
 *
 * @todo use standingChargeUnitType to determine the copy displayed
 *   - Currently returned as null from kraken for this product
 */
export const StandingChargesAllElectric: FC<{
	gridOperatorArea?: string;
	standingChargePerDay?: string | null;
	standingCharges?: StandingChargeFragment[];
	variant?: StandingChargesAllElectricVariant;
}> = ({
	gridOperatorArea,
	standingCharges = [],
	standingChargePerDay,
	variant = 'mypage',
}) => {
	const { t } = useTranslation();

	if (standingCharges.length === 0 && !standingChargePerDay) {
		return null;
	}

	const [firstStandingCharge, secondStandingCharge] = standingCharges;

	return (
		<div className="w-full text-base">
			<h3
				className={clsx('mb-3 font-bold', {
					'text-xl': variant === 'tariffs',
				})}
			>
				{t('common:basic-charge')}{' '}
				{gridOperatorArea && `(${gridOperatorArea}エリア)`}:
			</h3>
			<div
				className={clsx({
					'space-y-0': variant === 'mypage',
					'space-y-1 text-xs': variant === 'onboarding',
				})}
			>
				{standingChargePerDay ? (
					<p>
						{' '}
						{t('price-with-yen', {
							price: currencyFormatter.format(Number(standingChargePerDay)),
						})}
						/kW/日
					</p>
				) : standingCharges.length > 1 ? (
					<p>
						10kWまで{' '}
						{t('price-with-yen', {
							price: currencyFormatter.format(
								Number(firstStandingCharge.pricePerUnitIncTax)
							),
						})}
						/日
					</p>
				) : (
					<p>
						{t('price-with-yen', {
							price: currencyFormatter.format(
								Number(firstStandingCharge.pricePerUnitIncTax)
							),
						})}
						/kW/日
					</p>
				)}
				{secondStandingCharge && (
					<p>
						10kWをこえる1kW につき&nbsp;
						{t('price-with-yen', {
							price: currencyFormatter.format(
								Number(secondStandingCharge.pricePerUnitIncTax)
							),
						})}
						/日
					</p>
				)}
			</div>
		</div>
	);
};
