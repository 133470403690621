import { FC } from 'react';

export const ZeroWithEmphasis: FC = () => {
	return (
		<svg
			width="28"
			height="29"
			viewBox="0 0 28 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M28.0016 14.4766C28.0016 15.6722 26.5676 16.6628 26.2736 17.7656C25.9796 18.8684 26.7058 20.4795 26.128 21.4784C25.5502 22.4773 23.8055 22.6359 22.9828 23.4586C22.161 24.2804 22.0163 26.0177 21.0026 26.6038C19.9888 27.19 18.4306 26.4443 17.2897 26.7495C16.1869 27.0444 15.1964 28.4774 14.0008 28.4774C12.8052 28.4774 11.8146 27.0435 10.7118 26.7495C9.60902 26.4554 7.99794 27.1817 6.999 26.6038C5.98616 26.0177 5.84147 24.2813 5.01877 23.4586C4.19606 22.6359 2.45976 22.4922 1.87357 21.4784C1.28739 20.4646 2.03311 18.9064 1.72795 17.7656C1.43301 16.6628 0 15.6722 0 14.4766C0 13.2811 1.43393 12.2905 1.72795 11.1877C2.02198 10.0849 1.29573 8.47377 1.87357 7.47483C2.45141 6.4759 4.19606 6.3173 5.01877 5.4946C5.84147 4.67189 5.98523 2.93559 6.999 2.3494C7.99794 1.77156 9.571 2.50894 10.7118 2.20378C11.8146 1.90884 12.8052 0.47583 14.0008 0.47583C15.1964 0.47583 16.1869 1.90976 17.2897 2.20378C18.4306 2.50894 20.0036 1.77156 21.0026 2.3494C22.0154 2.93559 22.1601 4.67189 22.9828 5.4946C23.8055 6.3173 25.5418 6.46106 26.128 7.47483C26.7058 8.47377 25.9685 10.0468 26.2736 11.1877C26.5788 12.3285 28.0016 13.2811 28.0016 14.4766Z"
				fill="#5840FF"
			/>
			<path
				d="M18.1116 6.56497C17.0598 5.51225 15.6064 4.86206 14.0009 4.86206C10.7899 4.86206 8.18726 7.46466 8.18726 10.6757V18.2767C8.18726 21.4877 10.7899 24.0903 14.0009 24.0903C17.2119 24.0903 19.8145 21.4877 19.8145 18.2767V10.6757C19.8145 9.07018 19.1634 7.61677 18.1116 6.56497ZM14.0009 8.43484C14.7244 8.43484 15.3783 8.72793 15.8522 9.20189C15.9375 9.28722 16.0173 9.37904 16.0906 9.47551L11.3825 15.3448V11.0532C11.3825 9.60721 12.5549 8.43484 14.0009 8.43484ZM14.0009 20.5175C13.1624 20.5175 12.4158 20.1224 11.9372 19.5093L16.6193 13.6716V17.8992C16.6193 19.3452 15.4469 20.5175 14.0009 20.5175Z"
				fill="#60F0F8"
			/>
		</svg>
	);
};
