import { FC } from 'react';

type EarthIconProps = {
	height?: number;
	width?: number;
};

const EarthIcon: FC<EarthIconProps> = ({ width = 19, height = 19 }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 19 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.0429 0.866027C10.327 1.36393 10.2676 1.98737 9.89443 2.4225L9.22859 3.20201C8.796 3.70246 8.79175 4.44464 9.21586 4.95357L9.79265 5.64063C10.0217 5.9163 10.0429 6.31071 9.84354 6.61183C9.63997 6.91295 9.25827 7.04442 8.91475 6.92991L7.89265 6.60335C7.6297 6.50156 7.30738 6.52701 7.02747 6.66696L6.773 6.78996C6.39131 6.98505 6.20894 7.42612 6.34466 7.83326C6.40827 8.03259 6.54823 8.20223 6.73059 8.30402L8.19376 9.14375C8.60515 9.37701 9.07167 9.5 9.50425 9.5H11.3703C11.9089 9.5 12.4306 9.7163 12.8123 10.098L12.9735 10.2592C13.3551 10.6408 13.5714 11.1625 13.5714 11.7011V12.0489C13.5714 12.7826 13.1813 13.4569 12.5451 13.8174L12.2016 14.0167C11.5612 14.3815 11.0947 14.9964 10.9165 15.7132L10.7978 16.1882C10.6239 16.8413 10.098 17.4223 9.41095 17.6174C8.77479 17.7998 8.1047 17.3248 8.1047 16.6632V15.0092C8.1047 14.5426 7.87992 14.1185 7.42613 13.9107C7.04867 13.7029 6.74756 13.2788 6.74756 12.8123V11.1201C6.74756 10.3609 6.28528 9.69509 5.51765 9.48728L2.99421 8.75781C2.37586 8.57969 1.83936 8.18951 1.48227 7.65089L0.562378 6.27254C1.88347 2.6142 5.38617 0 9.46184 0C9.51698 0 9.53394 0 9.54667 0L10.0429 0.866027ZM17.838 4.94509C18.5801 6.29799 19 7.85022 19 9.5C19 10.0259 18.9194 10.5391 18.877 11.0438L16.9304 10.3949C16.5232 10.2592 16.2051 9.94107 16.0694 9.53393L15.3315 7.32009C15.1067 6.64152 15.4502 5.89933 16.1161 5.63214L17.838 4.94509Z"
				fill="#18F0B8"
			/>
			<path
				d="M0.562366 6.27254L1.48225 7.65089C1.83935 8.18951 2.37585 8.57969 2.9942 8.75781L5.51763 9.48728C6.28527 9.69509 6.74755 10.3609 6.74755 11.1201V12.8123C6.74755 13.2788 7.04866 13.7029 7.42612 13.8725C7.87991 14.1185 8.10469 14.5426 8.10469 15.0092V16.6632C8.10469 17.3248 8.77478 17.7998 9.41094 17.6174C10.098 17.4223 10.6239 16.8413 10.7978 16.1882L10.9165 15.7132C11.0946 14.9964 11.5612 14.3815 12.2016 14.0125L12.5451 13.8174C13.1813 13.4569 13.5714 12.7826 13.5714 12.0489V11.7011C13.5714 11.1583 13.3551 10.6408 12.9734 10.2592L12.8123 10.098C12.4306 9.71629 11.9089 9.46183 11.3703 9.46183H9.50424C9.07165 9.46183 8.60513 9.37701 8.19375 9.14375L6.73058 8.30402C6.54821 8.20223 6.40826 8.03259 6.34464 7.83326C6.20893 7.42612 6.39129 6.9808 6.77299 6.78996L7.02746 6.66696C7.30737 6.52701 7.62969 6.50156 7.89263 6.60335L8.91473 6.92991C9.25826 7.04442 9.63996 6.91295 9.84353 6.61183C10.0429 6.31071 10.0217 5.91629 9.79263 5.64062L9.21585 4.95357C8.79174 4.44464 8.79598 3.70246 9.22857 3.20201L9.89442 2.4225C10.2676 1.98694 10.327 1.36393 10.0429 0.866027L9.54665 0C13.1219 0.0178125 16.2306 2.01069 17.8379 4.94509L16.1161 5.63214C15.4502 5.89933 15.1067 6.63728 15.3315 7.32009L16.0694 9.53393C16.2051 9.94107 16.5232 10.2592 16.9304 10.3949L18.877 11.0437C18.1391 15.5562 14.2203 18.9618 9.5 18.9618C4.2538 18.9618 0 14.7462 0 9.46183C0 8.36763 0.198482 7.27768 0.562366 6.27254Z"
				fill="#6675F6"
			/>
		</svg>
	);
};

export { EarthIcon };
