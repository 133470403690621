import { FC } from 'react';

type SunIconProps = {
	width?: number;
};

const SunIcon: FC<SunIconProps> = ({ width = 15 }) => {
	return (
		<svg
			width={width}
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			aria-labelledby="sun-icon"
		>
			<title id="sun-icon" lang="ja">
				太陽アイコン {/* Sun symbol icon */}
			</title>
			<path
				d="M8.50003 5.31296C7.65477 5.31296 6.84414 5.64873 6.24645 6.24642C5.64876 6.84411 5.31299 7.65474 5.31299 8.5C5.31299 9.34525 5.64876 10.1559 6.24645 10.7536C6.84414 11.3513 7.65477 11.687 8.50003 11.687C9.34528 11.687 10.1559 11.3513 10.7536 10.7536C11.3513 10.1559 11.6871 9.34525 11.6871 8.5C11.6871 7.65474 11.3513 6.84411 10.7536 6.24642C10.1559 5.64873 9.34528 5.31296 8.50003 5.31296Z"
				fill="#FFA26B"
			/>
			<path
				d="M12.0024 0.0410671C12.1684 0.110784 12.2879 0.260176 12.3211 0.436127L12.9818 4.01823L16.5639 4.67555C16.7398 4.70875 16.8892 4.82826 16.9589 4.99426C17.0287 5.16025 17.0087 5.34948 16.9058 5.49887L14.8376 8.5L16.9058 11.4978C17.0087 11.6472 17.0287 11.8364 16.9589 12.0024C16.8892 12.1684 16.7398 12.2879 16.5639 12.3211L12.9818 12.9818L12.3211 16.5639C12.2879 16.7398 12.1684 16.8892 12.0024 16.9589C11.8364 17.0287 11.6472 17.0087 11.4978 16.9058L8.5 14.8376L5.50219 16.9058C5.3528 17.0087 5.16357 17.0287 4.99758 16.9589C4.83158 16.8892 4.71207 16.7398 4.67887 16.5639L4.01823 12.9818L0.436127 12.3211C0.260176 12.2879 0.110784 12.1684 0.0410671 12.0024C-0.0286493 11.8364 -0.00873034 11.6472 0.0941845 11.4978L2.16244 8.5L0.0941845 5.50219C-0.00873034 5.3528 -0.0286493 5.16357 0.0410671 4.99758C0.110784 4.83158 0.260176 4.71207 0.436127 4.67887L4.01823 4.01823L4.67887 0.436127C4.71207 0.260176 4.83158 0.110784 4.99758 0.0410671C5.16357 -0.0286493 5.3528 -0.00873034 5.50219 0.0941845L8.5 2.16244L11.4978 0.0941845C11.6472 -0.00873034 11.8364 -0.0286493 12.0024 0.0410671ZM8.5 12.7494C9.62701 12.7494 10.7079 12.3017 11.5048 11.5048C12.3017 10.7079 12.7494 9.62701 12.7494 8.5C12.7494 7.37299 12.3017 6.29214 11.5048 5.49523C10.7079 4.69832 9.62701 4.25061 8.5 4.25061C7.37299 4.25061 6.29214 4.69832 5.49523 5.49523C4.69832 6.29214 4.25061 7.37299 4.25061 8.5C4.25061 9.62701 4.69832 10.7079 5.49523 11.5048C6.29214 12.3017 7.37299 12.7494 8.5 12.7494Z"
				fill="#FFD84F"
			/>
		</svg>
	);
};

export { SunIcon };
