/* eslint-disable tailwindcss/no-custom-classname */
import { FC } from 'react';

const CrownCircle: FC<{ height?: number; width?: number }> = ({
	height = 27,
	width = 27,
}) => {
	return (
		<svg
			aria-labelledby="crown-circle"
			width={width}
			height={height}
			viewBox="0 0 27 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="crown-circle" lang="ja">
				{/* Illustration of a crown. */}
				王冠のイラスト
			</title>
			<g clipPath="url(#clip0_419_3030)">
				<path
					d="M13.5009 26.5642C20.7156 26.5642 26.5642 20.7156 26.5642 13.5009C26.5642 6.28617 20.7156 0.4375 13.5009 0.4375C6.28617 0.4375 0.4375 6.28617 0.4375 13.5009C0.4375 20.7156 6.28617 26.5642 13.5009 26.5642Z"
					fill="url(#paint0_linear_419_3030)"
				/>
				<path
					d="M13.4997 25.0443C19.8756 25.0443 25.0443 19.8756 25.0443 13.4997C25.0443 7.12378 19.8756 1.95508 13.4997 1.95508C7.12378 1.95508 1.95508 7.12378 1.95508 13.4997C1.95508 19.8756 7.12378 25.0443 13.4997 25.0443Z"
					fill="white"
				/>
				<path
					d="M7.4375 9.87398V18.6722H19.5601V9.59766L17.2925 12.6035C16.9276 13.0866 16.2104 13.1055 15.8223 12.6415L13.4998 9.87398L11.0445 12.7997C10.6543 13.2659 9.92867 13.2427 9.56797 12.7554L7.4375 9.87609V9.87398Z"
					fill="#FFA26B"
				/>
				<path
					d="M8.37602 11.1435C8.14398 11.3966 7.8107 11.557 7.43945 11.557V10.8693C7.47953 10.5213 7.43945 10.2871 7.43945 10.2871L8.06383 10.7069L8.37813 11.1456L8.37602 11.1435Z"
					fill="#FF9151"
				/>
				<path
					d="M18.4707 11.0506C18.7407 11.3459 19.1309 11.5316 19.5634 11.5316V10.73C19.517 10.3229 19.5634 10.0508 19.5634 10.0508L18.8356 10.538L18.4707 11.0485V11.0506Z"
					fill="#FF9151"
				/>
				<path
					d="M13.8416 10.174L14.5018 11.0684C14.2698 11.3658 13.907 11.5578 13.4998 11.5578C13.0927 11.5578 12.732 11.3658 12.5 11.0705C12.5 11.0705 13.1307 10.1761 13.2193 10.1276C13.8331 9.788 13.8437 9.47581 13.8437 10.1761L13.8416 10.174Z"
					fill="#FF9151"
				/>
				<path
					d="M19.5628 10.8678C20.2641 10.8678 20.8327 10.2993 20.8327 9.59797C20.8327 8.89665 20.2641 8.32812 19.5628 8.32812C18.8615 8.32812 18.293 8.89665 18.293 9.59797C18.293 10.2993 18.8615 10.8678 19.5628 10.8678Z"
					fill="#FFA26B"
				/>
				<path
					d="M13.5003 10.8678C14.2016 10.8678 14.7702 10.2993 14.7702 9.59797C14.7702 8.89665 14.2016 8.32812 13.5003 8.32812C12.799 8.32812 12.2305 8.89665 12.2305 9.59797C12.2305 10.2993 12.799 10.8678 13.5003 10.8678Z"
					fill="#FFA26B"
				/>
				<path
					d="M7.43781 10.8678C8.13913 10.8678 8.70766 10.2993 8.70766 9.59797C8.70766 8.89665 8.13913 8.32812 7.43781 8.32812C6.7365 8.32812 6.16797 8.89665 6.16797 9.59797C6.16797 10.2993 6.7365 10.8678 7.43781 10.8678Z"
					fill="#FFA26B"
				/>
				<path
					d="M19.5601 17.8242H7.4375V18.6722H19.5601V17.8242Z"
					fill="#FF9151"
				/>
			</g>
			<defs>
				<linearGradient
					id="paint0_linear_419_3030"
					x1="0.4375"
					y1="13.5009"
					x2="26.5642"
					y2="13.5009"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#949FFF" />
					<stop offset="1" stopColor="#F050F8" />
				</linearGradient>
				<clipPath id="clip0_419_3030">
					<rect width="27" height="27" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export { CrownCircle };
