import { FC } from 'react';

type YenNoFeesProps = {
	height?: number;
	width?: number;
};

const YenNoFees: FC<YenNoFeesProps> = ({ height = 22, width = 22 }) => {
	return (
		<svg
			aria-labelledby="yen-no-fees"
			width={width}
			height={height}
			viewBox="0 0 28 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<title id="yen-no-fees" lang="ja">
				{/* No charge illustration */}
				無料のイラスト
			</title>
			<path
				d="M24.8967 5.26326L2.6009 5.13961L2.53935 16.738L24.8351 16.8617L24.8967 5.26326Z"
				fill="#6675F6"
			/>
			<path
				d="M23.9603 16.1747L3.41778 16.0601L3.46907 5.77505L24.0145 5.89037L23.9596 16.1777L23.9603 16.1747ZM3.68047 15.8002L23.7004 15.912L23.7518 6.15023L3.73187 6.03843L3.68047 15.8002Z"
				fill="#5840FF"
			/>
			<path
				d="M9.27134 6.50323L4.27459 6.47645L4.27226 6.86599L9.26901 6.89277L9.27134 6.50323Z"
				fill="#5840FF"
			/>
			<path
				d="M22.8369 10.8578L17.7137 10.829L17.712 11.098L22.8352 11.1268L22.8369 10.8578Z"
				fill="#5840FF"
			/>
			<path
				d="M23.1862 13.6506L17.1757 13.6163L17.1652 15.3104L23.1757 15.3447L23.1862 13.6506Z"
				fill="#5840FF"
			/>
			<path
				d="M3.53203 14.5811L8.36437 14.6093C8.70422 14.6122 9.03116 14.7484 9.27103 14.9908C9.51091 15.2333 9.64373 15.5619 9.64369 15.9025L3.48126 15.8694L3.53134 14.584L3.53203 14.5811Z"
				fill="#5840FF"
			/>
			<path
				d="M23.7827 7.34169L18.9467 7.31578C18.6069 7.31282 18.2799 7.17668 18.0401 6.9342C17.8002 6.69172 17.6667 6.36603 17.6674 6.02255L23.8298 6.05563L23.7798 7.341L23.7827 7.34169Z"
				fill="#5840FF"
			/>
			<path
				d="M20.2642 12.0754C20.0449 12.073 19.8285 12.0064 19.6465 11.8859C19.4651 11.7626 19.3233 11.5897 19.2388 11.384C19.1566 11.1819 19.1355 10.9571 19.1802 10.7416C19.225 10.5262 19.3306 10.3283 19.4879 10.1735C19.6453 10.0188 19.8434 9.91395 20.0577 9.87175C20.2721 9.82955 20.4953 9.85446 20.698 9.93957C20.9008 10.0247 21.0744 10.1679 21.1951 10.3512C21.3159 10.5346 21.3793 10.7508 21.3799 10.9708C21.3785 11.2646 21.2594 11.546 21.0493 11.7533C20.8393 11.9606 20.558 12.0767 20.2613 12.0747L20.2642 12.0754ZM20.275 10.1182C20.1077 10.1188 19.9417 10.1663 19.8025 10.2572C19.6627 10.3511 19.5547 10.4803 19.4872 10.6347C19.4227 10.7897 19.4044 10.9587 19.4365 11.1242C19.4686 11.2897 19.5472 11.4414 19.6649 11.559C19.7819 11.6796 19.9335 11.7588 20.0984 11.7947C20.264 11.8276 20.4348 11.8123 20.5889 11.7497C20.743 11.687 20.8768 11.5794 20.9715 11.4408C21.0662 11.3022 21.1175 11.1378 21.1168 10.9705C21.1176 10.7447 21.0293 10.5287 20.8716 10.3707C20.7147 10.2097 20.4972 10.1212 20.2721 10.1175L20.275 10.1182Z"
				fill="#5840FF"
			/>
			<path
				d="M13.6276 14.9822C15.4518 14.9926 16.9403 13.2145 16.9502 11.0125C16.963 8.81123 15.4931 7.01474 13.6689 7.00443C11.8447 6.99412 10.3562 8.77214 10.3463 10.9741C10.3364 13.1761 11.8034 14.9719 13.6276 14.9822Z"
				fill="#5840FF"
			/>
			<path
				d="M6.87548 11.289C7.34753 11.4007 7.81883 11.1096 7.93045 10.6376C8.04208 10.1655 7.75105 9.69424 7.279 9.58262C6.80694 9.47099 6.33565 9.76202 6.22402 10.2341C6.1124 10.7061 6.40343 11.1774 6.87548 11.289Z"
				fill="#5840FF"
			/>
			<path
				d="M8.52179 11.8503L5.52243 11.8345L5.51848 12.4404L8.51784 12.4561L8.52179 11.8503Z"
				fill="#5840FF"
			/>
			<path
				d="M23.1253 7.96159L20.1259 7.94583L20.1254 8.24912L23.1248 8.26487L23.1253 7.96159Z"
				fill="#5840FF"
			/>
			<g clipPath="url(#clip0_416_2690)">
				<path
					d="M19.739 17.3579L8.55581 3.49062C8.31033 3.18622 7.87366 3.14607 7.58049 3.40095L7.23122 3.7046C6.93804 3.95948 6.89938 4.41286 7.14486 4.71726L18.328 18.5846C18.5735 18.889 19.0102 18.9291 19.3033 18.6742L19.6526 18.3706C19.9458 18.1157 19.9845 17.6623 19.739 17.3579Z"
					fill="#F050F8"
				/>
				<path
					d="M8.55594 18.5845L19.7391 4.71718C19.9846 4.41278 19.9459 3.9594 19.6528 3.70452L19.3035 3.40087C19.0103 3.14599 18.5736 3.18614 18.3282 3.49053L7.14499 17.3578C6.89951 17.6622 6.93817 18.1156 7.23135 18.3705L7.58062 18.6742C7.8738 18.929 8.31046 18.8889 8.55594 18.5845Z"
					fill="#F050F8"
				/>
			</g>
			<defs>
				<clipPath id="clip0_416_2690">
					<rect
						width="12.9173"
						height="15.6084"
						fill="white"
						transform="translate(6.98438 3.23438)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};

export { YenNoFees };
