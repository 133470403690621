import { Stack } from '@krakentech/coral';
import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import { ConsumptionChargesProductCard } from '@/components/shared/ConsumptionCharges';
import {
	GRID_AREA_NAME_BY_GRID_OPERATOR_CODE,
	GridOperatorCode,
} from '@/constants/industry/gridOperator';
import {
	StandingChargeUnitType,
	getStandingChargeUnitTranslation,
} from '@/constants/industry/industry';
import { Scalars, SteppedConsumptionRate } from '@/services/typed-graphql-sdk';

interface ProductRatesAndCharges {
	amperage?: Maybe<number>;
	cancellationFee?: Maybe<Scalars['Int']>;
	consumptionCharges: SteppedConsumptionRate[];
	gridOperatorCode?: GridOperatorCode;
	handlingFee?: Maybe<Scalars['Int']>;
	kva: number;
	standingChargePerDay?: string | null;
	standingChargeUnitType: StandingChargeUnitType;
}
export const ProductRatesAndCharges: FC<ProductRatesAndCharges> = ({
	amperage,
	cancellationFee = 0,
	consumptionCharges,
	gridOperatorCode,
	handlingFee = 0,
	kva,
	standingChargePerDay,
	standingChargeUnitType,
}) => {
	const { t } = useTranslation();

	return (
		<Stack direction="vertical" gap="md">
			<Stack.Item>
				<h3 className="text-xl font-bold">
					{t('common:basic-charge')}
					{gridOperatorCode && (
						<>
							&nbsp;(
							{`${GRID_AREA_NAME_BY_GRID_OPERATOR_CODE[gridOperatorCode]}${t(
								'electricity-supply-area'
							)}`}
							)
						</>
					)}
				</h3>
			</Stack.Item>
			{/* Price per day: 26yen/40A/day */}
			<Stack.Item>
				<p className="font-bold text-ice">{t('common:basic-charge')}</p>
				<div className="text-sm text-ice">
					{t('price-with-yen', {
						price: standingChargePerDay,
					})}
					/{standingChargeUnitType !== 'YEN_DAY' && (kva || amperage || '')}
					{getStandingChargeUnitTranslation(standingChargeUnitType, t)}
				</div>
			</Stack.Item>
			{/* Consumption Charges: 25.6円/kwvh (0 - 210kwh) */}
			<Stack.Item>
				{consumptionCharges.length > 1 && (
					<>
						<p className="text-ice">{t('common:consumption-charge')}:</p>
						<ConsumptionChargesProductCard
							consumptionCharges={consumptionCharges}
						/>
					</>
				)}
			</Stack.Item>
			<Stack.Item>
				<p className="text-ice">
					{t('handling-fee')}
					<Trans i18nKey="price-with-yen" values={{ price: handlingFee }} />
				</p>
				<p className="text-ice">
					{t('cancellation-fee')}
					<Trans i18nKey="price-with-yen" values={{ price: cancellationFee }} />
				</p>
			</Stack.Item>
			<Stack.Item>
				<p className="text-ice">{t('obj:product.price-is-tentative-2')}</p>
			</Stack.Item>
		</Stack>
	);
};
