import { Stack } from '@krakentech/coral';
import { Trans, useTranslation } from 'next-i18next';
import { FC } from 'react';

import {
	GRID_AREA_NAME_BY_GRID_OPERATOR_CODE,
	GridOperatorCode,
} from '@/constants/industry/gridOperator';
import { Scalars, SteppedConsumptionRate } from '@/services/typed-graphql-sdk';

interface ProductRatesAndCharges {
	amperage?: Maybe<number>;
	cancellationFee?: Maybe<Scalars['Int']>;
	consumptionCharges: SteppedConsumptionRate[];
	gridOperatorCode?: GridOperatorCode;
	handlingFee?: Maybe<Scalars['Int']>;
}

export const ProductRatesAndChargesSimple: FC<ProductRatesAndCharges> = ({
	consumptionCharges,
	gridOperatorCode,
}) => {
	const { t } = useTranslation();

	return (
		<Stack direction="vertical" gap="sm">
			<Stack.Item>
				<h3 className="text-xl font-bold">
					{t('common:basic-charge')}
					{gridOperatorCode && (
						<>
							&nbsp;(
							{`${GRID_AREA_NAME_BY_GRID_OPERATOR_CODE[gridOperatorCode]}${t(
								'electricity-supply-area'
							)}`}
							)
						</>
					)}
				</h3>
			</Stack.Item>
			<Stack.Item>
				<p className="font-bold text-ice">
					{t('common:basic-charge')}{' '}
					<Trans i18nKey="price-with-yen" values={{ price: 0 }} />
				</p>
			</Stack.Item>
			<Stack.Item>
				<p className="font-bold">
					{t('obj:product.fuel-cost-adjustment-simple')}{' '}
					<Trans i18nKey="price-with-yen" values={{ price: 0 }} />
				</p>
			</Stack.Item>
			<Stack.Item>
				<div className="flex font-bold text-ice">
					<p className="mr-2">{t('common:consumption-charge')} </p>
					{consumptionCharges.map((charge, index) => (
						<div key={`${charge}.${index}`}>
							<Trans
								i18nKey="obj:product.price-perkwh"
								values={{ price: charge.pricePerUnitIncTax }}
								components={{ span: <span>円/kWh</span> }}
							/>
						</div>
					))}
				</div>
			</Stack.Item>
			<Stack.Item>
				<p className="text-ice">{t('obj:product.price-is-tentative-2')}</p>
			</Stack.Item>
		</Stack>
	);
};
